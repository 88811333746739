<template>
  <v-app>
    <v-main>
      <Toolbar />
      <v-snackbar v-model="snackbarModal">
        {{ snackbarText }}
      </v-snackbar>
      <LateralDrawer />
      <div class="prueba">
      <router-view></router-view>
      </div>
      <Footer />
    </v-main>
  </v-app>
</template>
<script>
import LateralDrawer from "@/components/lateral-drawer.vue";
import Footer from "@/components/footer.vue";
import { mapActions, mapState } from "vuex";
import Toolbar from './components/Toolbar.vue';

export default {
  components: { LateralDrawer, Footer, Toolbar },
  data: () => ({
    timeoutSnackbar: 3000,
  }),
  mounted() {
    this.getProductsToCartApp();
  },
  computed: {
    ...mapState(["snackbarText"]),
    snackbarModal: {
      get() {
        return this.$store.state.snackbarModal;
      },
      set(val) {
        return val;
      },
    },
  },
  methods: {
    ...mapActions(["getProductsToCart"]),
    getProductsToCartApp() {
      this.getProductsToCart();
    },
  },
  watch: {},
};
</script>
<style lang="scss">
@import url(./css/general.scss);
.prueba{
  min-height: 100vh;
}
</style>