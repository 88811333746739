import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    products: [],
    categories: [],
    product: {},
    nameCommerce: 'Cesdigital',
    apiURL: process.env.VUE_APP_RUTA_API,
    cart: [],
    snackbarModal: false,
    commerces: [],
    snackbarText: '',
    drawer: false,
  },
  mutations: {
    changeDrawer(state, payload) {
      state.drawer = payload
    },
    getProductsToCart(state) {
      if (localStorage.getItem('cart')) {
        state.cart = JSON.parse(localStorage.getItem('cart'))
      }
    },
    setProductInCart(state, product) {
      if (state.cart.find(item => item._id === product._id)) {
        let productCreated = state.cart.find(item => item._id === product._id)
        productCreated.quantity++
        if (productCreated.quantity > product.stok) {
          productCreated.quantity -= 1
          state.snackbarText = `No hay mas existencias del producto, maximo ${product.stok}`
        } else {         
          state.snackbarText = `${product.name} agregado al carrito`
        }
      } else {
        state.snackbarText = `${product.name} agregado al carrito`
        product.quantity = 1
        state.cart.push(product)
      }
      state.snackbarModal = true
      setTimeout(() => {
        state.snackbarModal = false
      }, 3000);
      localStorage.setItem('cart', JSON.stringify(state.cart))
    },
    deleteProductCart(state, product) {
      let productCreated = state.cart.find(item => item._id === product._id)
      productCreated.quantity--
      if (productCreated.quantity === 0) {
        state.cart.splice(state.cart.indexOf(productCreated), 1)
      }
      state.snackbarText = `${product.name} eliminado del carrito`
      localStorage.setItem('cart', JSON.stringify(state.cart))
      state.snackbarModal = true
      setTimeout(() => {
        state.snackbarModal = false
      }, 3000);
    },
    setProducsCategorie(state, categorie) {
      axios.get(`${state.apiURL}/categories/${categorie}`)
      .then(response => {
        state.products = response.data
      })
      .catch(error => {
        console.log(error)
      })
    },
    setProduct(state, product) {
      axios.get(`${state.apiURL}/products/${product}`)
      .then(response => {
        state.product = response.data
      })
      .catch(error => {
        console.log(error)
      })
    },
    setCategories(state) {
      axios.get(`${state.apiURL}/categories`)
        .then(response => {
          state.categories = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    getCommerces(state) {
      axios.get(`${state.apiURL}/commerces`)
        .then(response => {
          state.commerces = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    createCommerce(state, commerce) {
      axios.post(`${state.apiURL}/commerce/new-commerce`, commerce)
        .then(response => {
          state.snackbarText = response.data
          state.snackbarModal = true
          setTimeout(() => {
            state.snackbarModal = false
          }, 3000);
        })
        .catch(error => {
          console.log(error)
        })
    },
    createCategory(state, nameCommerce) {
      axios.post(`${state.apiURL}/categories/new-categorie/${state.nameCommerce}`, nameCommerce)
        .then(response => {
          state.snackbarText = response.data
          state.snackbarModal = true
          setTimeout(() => {
            state.snackbarModal = false
          }, 3000);
        })
        .catch(error => {
          console.log(error)
        })
    },
    categoryUpdate(state, category) {
      axios.put(`${state.apiURL}/category-update/${state.nameCommerce}`, category)
        .then(response => {
          state.snackbarText = response.data
          state.snackbarModal = true
          setTimeout(() => {
            state.snackbarModal = false
          }, 3000);
        })
        .catch(error => {
          console.log(error)
        })
    },
    commerceUpdate(state, commerce) {
      axios.put(`${state.apiURL}/commerce-update/${commerce._id}`, commerce)
        .then(response => {
          state.snackbarText = response.data
          state.snackbarModal = true
          setTimeout(() => {
            state.snackbarModal = false
          }, 3000);
        })
        .catch(error => {
          console.log(error)
        })
    },
    commerceDelete(state, commerce) {
      axios.delete(`${state.apiURL}/commerce-delete/${commerce._id}`)
        .then(response => {
          state.snackbarText = response.data
          state.snackbarModal = true
          setTimeout(() => {
            state.snackbarModal = false
          }, 3000);
        })
        .catch(error => {
          console.log(error)
        })
    },
    getProductsCommerce(state, nameCommerce) {
      axios.get(`${state.apiURL}/products/commerce/${nameCommerce}`)
        .then(response => {
          state.products = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    getCategoriesCommerce(state, nameCommerce) {
      axios.get(`${state.apiURL}/categories/commerce/${nameCommerce}`)
        .then(response => {
          state.categories = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
  actions: {
    setCategories({commit}) {
      commit('setCategories')
    },
    setProducsCategorie({commit}, categorie) {
      commit('setProducsCategorie', categorie)
    },
    setProduct({commit}, product) {
      commit('setProduct', product)
    },
    setProductInCart({commit}, product) {
      commit('setProductInCart', product)
    },
    getProductsToCart({commit}) {
      commit('getProductsToCart')
    },
    deleteProductCart({commit}, product) {
      commit('deleteProductCart', product)
    },
    getCommerces({commit}) {
      commit('getCommerces')
    },
    changeDrawer({commit}, payload) {
      commit('changeDrawer', payload)
    },
    createCommerce({commit}, commerce) {
      commit('createCommerce', commerce)
    },
    commerceUpdate({commit}, commerce) {
      commit('commerceUpdate', commerce)
    },
    commerceDelete({commit}, commerce) {
      commit('commerceDelete', commerce)
    },
    getProductsCommerce({commit}, nameCommerce) {
      commit('getProductsCommerce', nameCommerce)
    },
    getCategoriesCommerce({commit}, nameCommerce) {
      commit('getCategoriesCommerce', nameCommerce)
    },
    createCategory({commit}, nameCommerce) {
      commit('createCategory', nameCommerce)
    },
    categoryUpdate({commit}, category) {
      commit('categoryUpdate', category)
    },
  },
  modules: {
  }
})
