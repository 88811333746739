<template>
  <div class="container-drawer">
    <v-navigation-drawer ref="referencia" class="drawer" v-model="changeDrawer" absolute right temporary>
      <v-card
        class="card-cart mt-1"
        v-for="(product, index) of cart"
        :key="index"
      >
        <v-img
          class="white--text align-end image-cart"
          height="130px"
          :src="product.image"
          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        ></v-img>
        <div class="container-data pt-2 pb-2">
          <h4 class="title-product mb-2 mt-2">
            {{ product.name }}
          </h4>
          <p class="price-product">${{ formatPrice(product.price) }}</p>
          <span class="quantity-product">
            Cantidad: {{ product.quantity }}
          </span>
        </div>
        <div class="options-product pr-2">
          <v-btn
            class="btn-delete"
            icon
            @click="deleteProduct(product)"
          >
            <i class="fas fa-minus"></i>
          </v-btn>
          <v-btn
            class="btn-add"
            icon
            @click="addProduct(product)"
          >
            <i class="fas fa-plus"></i>
          </v-btn>
        </div>
      </v-card>
      <router-link to="/checkout" class="come-to-pay">
        <v-btn class="button orange">Ir a pagar</v-btn>
      </router-link>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "LateralDrawer",
  components: {},
  data: () => ({

    group: null,
  }),
  computed: {
    ...mapState(["cart", 'drawer']),
    changeDrawer:{
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        return val;
      },
    }
  },
  watch: {
    
  },
  methods: {
    ...mapActions(["getProductsToCart", 'setProductInCart', 'deleteProductCart']),
    getProductsToCartDrawer() {
      this.getProductsToCart();
    },
    addProduct(product) {
      this.setProductInCart(product);
    },
    deleteProduct(product) {
      this.deleteProductCart(product);
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
  },
  mounted(){}
};
</script>
<style lang="scss">
@import "./../css/cart.scss";
</style>