<template>
  <v-footer
    dark
    padless
  >
    <v-card
      class="flex"
      flat
      tile
    >
      <v-card-title class="teal">
        <strong class="subheading">Conoce nuestras redes sociales</strong>

        <v-spacer></v-spacer>

        <v-btn
          v-for="icon in icons"
          :key="icon"
          class="mx-4"
          dark
          icon
        >
        <i :class="icon"></i>
        </v-btn>
      </v-card-title>

      <v-card-text class="py-2 white--text text-center">
        {{ new Date().getFullYear() }} — <strong>CesDigital</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>
<script>
export default {
    name: "Footer",
    data() {
        return {
            icons: [
                "fa-brands fa-twitter",
                "fa-brands fa-facebook",
                "fa-brands fa-instagram",
                "fa-brands fa-youtube",
                "fa-brands fa-github",
            ],
        };
    },
}
</script>