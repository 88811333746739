<template>
  <div>
    <v-toolbar elevation="2">
        <router-link class="ml-4" to="/">
          <v-toolbar-title>CesDigital</v-toolbar-title>
        </router-link>
      <router-link class="ml-4" to="/categories">
        Categorias
      </router-link>
      <router-link class="ml-4" to="/categories/Moda">
        categories/moda
      </router-link>
      <router-link class="ml-4" to="/product/Frutas">
        product/Frutas
      </router-link>
      <router-link class="ml-4" to="/cms/products">
        cms/producst
      </router-link>
      <router-link class="ml-4" to="/cms/commerce">
        cms/commerces
      </router-link>
      <router-link class="ml-4" to="/cms/dashboard">
        cms/dashboard
      </router-link>
      <v-spacer></v-spacer>
      <v-btn icon @click="changeDrawer(!drawer)" @transitionend="changeDrawer(true)">
          <i class="fa-solid fa-ellipsis-vertical" ></i>
      </v-btn>
    </v-toolbar>
  </div>
</template>
<script>

import { mapActions, mapState } from "vuex";

export default {
  name: "Toolbar",
  data() {
    return {
    }
  },
  computed:{
    ...mapState(['drawer']),
  },
  watch:{
  },
  methods: {
    ...mapActions(["changeDrawer"]),
  }
};
</script>