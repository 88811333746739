import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue')
  },
  {
    path: '/categories',
    name: 'categories',
    component: () => import('../views/Categories.vue')
  },
  {
    path: '/categories/:categorie',
    name: 'products-category',
    component: () => import('../views/ProductsCategorie.vue')
  },
  {
    path: '/product/:product',
    name: 'Producto',
    component: () => import('../views/Product.vue')
  },
  {
    path: '/cms/login',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/cms/register',
    name: 'Register',
    component: () => import('../views/Register.vue')
  },
  {
    path: '/cms/dashboard',
    name: 'Dashboard',
    component: () => import('../views/Dashboard.vue')
  },
  {
    path: '/cms/products',
    name: 'crud-products',
    component: () => import('../views/CrudProducts.vue')
  },
  {
    path: '/cms/category',
    name: 'crud-category',
    component: () => import('../views/CrudCategory.vue')
  },
  {
    path: '/cms/commerce',
    name: 'crud-commerce',
    component: () => import('../views/CrudCommerce.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import('../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
